




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SpeechTextButton extends Vue {
  // TODO
  // safariの場合、音声ダウンロードして再生すると自動再生制限により音声が再生されない
  // 一旦はchromeのみを推奨としてsafari対応は保留

  @Prop()
  text!: string

  private processing = false
  private audio?: HTMLAudioElement | null

  private get playingAudio(): any[] {
    return this.$store.getters['media/playingAudio']
  }

  private async playText(): Promise<void> {
    // 複数回クリック防止
    if (this.processing) return
    // 他再生中の場合は処理しない
    if (this.playingAudio) return

    this.processing = true
    this.$store.commit('media/setPlayingAudio', true)

    try {
      if (this.audio != null) {
        this.audio.load()
        await this.audio.play()
      } else {
        const { data } = await Vue.prototype.$http.httpWithToken.get('/text_to_speech', { params: { text: this.text } })

        const audio = new Audio()
        audio.src = data.audioUrl
        audio.load()
        await audio.play()
        this.audio = audio
      }
    } catch (e) {
      console.error('failed to play audio.', e)
    } finally {
      this.processing = false
      this.$store.commit('media/setPlayingAudio', false)
    }
  }
}
