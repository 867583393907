















import { Vue, Prop, Component } from 'vue-property-decorator'
import SpeechTextButton from '@/components/molecules/SpeechTextButton.vue'

@Component({ components: { SpeechTextButton } })
export default class PracticeQuestion extends Vue {
  private subjectCode = this.$route.query?.subjectCode || Vue.prototype.$cookies.get('dataGdls').subjectCode

  private isMask = true

  @Prop()
  questionData!: {
    questionText?: string
    answerText?: string
  }

  @Prop()
  index!: number

  private toggleMask(isMask: boolean): void {
    this.$emit('toggle-mask', this.index, isMask)
    this.isMask = isMask
  }

  private get showPlayButton(): boolean {
    if (this.subjectCode === 'ei' && this.questionData.questionText != null)
      return RegExp(/^[a-zA-Z\s]+$/).test(this.questionData.questionText)
    return false
  }
}
