




















import { Component, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import PracticeQuestion from '@/components/organisms/PracticeQuestion.vue'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import VueRouter from 'vue-router'
import ScrollGuide from '@/components/modules/drills/atoms/ScrollGuide.vue'

Component.registerHooks(['beforeRouteUpdate'])

@Component({
  components: {
    ButtonBase,
    PracticeQuestion,
    ScrollGuide,
  },
})
export default class AssistMaterialPractice extends Mixins(LessonStudent) {
  private get isReport() {
    return this.$route.path.includes('report')
  }

  private curriculumSUnitId = this.$route.params.curriculumSUnitId

  private questionDataList: object[] = []

  private showAnswerIdx: number[] = []

  private noticeCurrentPageText = '暗記・練習中'

  private noticePageTitle = ''

  protected get lessonId() {
    return this.$store.getters['lessonActionCable/lessonId']
  }

  private async mounted() {
    Vue.prototype.$logger.info('-- AssistMaterialPractice mounted')
    this.$store.commit('drills/setProcessed', false)
    if (Vue.prototype.$gdlsCookiesV3.isV3()) {
      this.$store.commit(
        'drillsV3/setTitleResult',
        localStorage.getItem('drillsV3/setTitleResult') || 'テスト内容を確認！'
      )
    }
    // Web APIを投げて正誤入力に必要な情報を取得する
    let result: any
    try {
      result = await Vue.prototype.$http.httpWithToken.post('/drill/practice-start', {
        lessonId: this.lessonId,
        resultDrillId: Number(this.$route.query.resultDrillId),
      })
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }

    Vue.prototype.$logger.info(result.data.data)
    this.questionDataList = result.data.data

    this.noticeGroup()
    this.$store.commit('drills/setProcessed', true)
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    Vue.prototype.$logger.info('-- SelectSubject beforeRouteUpdate')

    this.noticeGroup()

    next()
  }

  private async noticeGroup() {
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({ currentPage: this.noticeCurrentPageText, pageTitle: this.noticePageTitle })

    // router.pushではsetTimeoutがクリアされないためセットする前にクリアして初期化しておく
    const setTimeoutIdQueueSize = await this.$store.dispatch('queue/size', 'setTimeoutIdQueue')
    for (let i = 0; i < setTimeoutIdQueueSize; i++) {
      const setTimeoutId = await this.$store.dispatch('queue/dequeue', 'setTimeoutIdQueue')
      clearTimeout(setTimeoutId)
    }

    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  get isShowAll(): boolean {
    // 1つでもマスクされている場合は「すべてひらく」、すべてのマスクが外れている場合は「すべてとじる」
    return this.questionDataList.length === this.showAnswerIdx.length
  }

  private toggleMask(idx: number, isMask: boolean): void {
    // 「すべてひらく/とじる」ボタンの管理のため、マスクされているpractice-questionコンポーネントをshowAnswerIdx内にリストしておく
    if (isMask) this.showAnswerIdx = this.showAnswerIdx.filter((n) => n !== idx)
    else !this.showAnswerIdx.includes(idx) && this.showAnswerIdx.push(idx)
  }

  private toggleAll(): void {
    const questions: any = this.$refs.questions
    const isMask = this.isShowAll
    // すべてのマスクが外れている → 各practice-questionのisMaskをtrue
    // １つでもマスクされている   → 各practice-questionのisMaskをfalse
    if (questions) questions.forEach((c: any) => c.toggleMask(isMask))
  }

  /**
   * ページの終了時に呼ぶメソッド
   * 問題表示中は問題終了APIをよび、解答時間を計測する
   *
   *
   */
  private async endPage(): Promise<any> {
    Vue.prototype.$logger.info('-- Transition endPage')

    // Web APIを投げる
    try {
      await Vue.prototype.$http.httpWithToken.post('/drill/practice-end', {
        lessonId: this.lessonId,
        resultDrillId: Number(this.$route.query.resultDrillId),
      })

      window.location.href = '/student/assist-material/select/OO'
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }
  }
}
